export const initProductFilter = () => {
    const elements = document.getElementsByClassName('chosen-filter');
    Array.from(elements).forEach((element) => {
        element.addEventListener('click', () => {
            const checkbox = document.getElementById(element.dataset.inputId);
            if (checkbox) {
                checkbox.click();
            }
        });
    });

    // show-more u boxů
    const filters = Array.from(document.getElementsByClassName('read-more-filters'));

    filters.forEach((el) =>
        el.addEventListener('click', () => {
            const hiddenElements = Array.from(el.parentNode.querySelectorAll('.hidden'));
            hiddenElements.forEach((hiddenElement) => hiddenElement.classList.remove('hidden'));
            el.hidden = true;
            el.parentElement.classList.add('shown-items');
            updateShownWidgets();
        })
    );

    // rozbalení boxů
    const widgets = Array.from(document.getElementsByClassName('widget__title'));

    widgets.forEach((title) =>
        title.addEventListener('click', () => {
            title.parentElement.classList.toggle('shown-filters');
            updateShownWidgets();
        })
    );

    updateShownWidgets();

    function updateShownWidgets() {
        const shownItems = Array.from(document.getElementsByClassName('shown-items'));
        const shownFilters = Array.from(document.getElementsByClassName('shown-filters'));
        const inputShownItems = document.getElementById('shown-items');
        const inputShownFilters = document.getElementById('shown-filters');

        if (inputShownItems) {
            inputShownItems.value = encodeURIComponent(JSON.stringify(shownItems.map((el) => el.id)));
            inputShownFilters.value = encodeURIComponent(JSON.stringify(shownFilters.map((el) => el.id)));
        }

        // const filter = document.querySelector('.show-all-filters');
        // if (!filter.classList.contains('.show-more') && window.innerWidth <= 768) {
        //     document.getElementById('products-anchor').scrollIntoView({
        //         behavior: 'smooth',
        //     });
        // }
    }
};
