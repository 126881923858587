export const saveToStorage = (key, item) => {
    localStorage.setItem(key, JSON.stringify(item));
};

export const loadFromStorage = (key) => {
    try {
        const result = JSON.parse(localStorage.getItem(key));

        return result;
    } catch (err) {
        console.log(err);
        return [];
    }
};
