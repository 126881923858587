function handleOnIncrementableButtonClick(button, isIncrementing) {
    const input = button.parentElement.parentElement.getElementsByTagName('input')[0];
    console.log(button);
    console.log(input);
    const incrementAmount =
        (isIncrementing ? 1.0 : -1.0) *
        parseFloat(button.getAttribute('data-' + (isIncrementing ? 'increase' : 'decrease') + '-amount'));

    if (!button || !input) {
        return;
    }

    try {
        console.log(inputValue);
        const inputValue = parseFloat(input.value);
        if (isNaN(inputValue)) {
            throw new Error('Value in input is not a number!');
        }
        if (inputValue <= -1.0 * incrementAmount && !isIncrementing) {
            return;
        }
        input.value = inputValue + incrementAmount;
    } catch (err) {
        console.error(err);
    }
}

export function initIncrementableInputs() {
    document.querySelectorAll('.incrementable__control--dec').forEach((element) => {
        element.addEventListener('click', (event) => {
            handleOnIncrementableButtonClick(element, false);
            event.preventDefault();
        });
    });
    document.querySelectorAll('.incrementable__control--inc').forEach((element) => {
        element.addEventListener('click', (event) => {
            handleOnIncrementableButtonClick(element, true);
            event.preventDefault();
        });
    });
}
