export const createGtmEcommerceEvent = (event, ecommerceData) => ({
    event: event,
    ecommerce: ecommerceData,
});

export const createGtmAddToCartEvent = (productsData, currency) =>
    createGtmEcommerceEvent('eec.add', {
        currencyCode: currency,
        add: {
            actionField: {
                list: 'Shopping cart',
            },
            products: productsData,
        },
    });

export const createGtmRemoveFromCartEvent = (productsData, currency) =>
    createGtmEcommerceEvent('eec.remove', {
        currencyCode: currency,
        remove: {
            actionField: {
                list: 'Shopping cart',
            },
            products: productsData,
        },
    });

export const createGtmProductClickEvent = (productsData, list) =>
    createGtmEcommerceEvent('eec.impressionClick', {
        click: {
            actionField: {
                list,
            },
            products: productsData,
        },
    });

export const createGtmCheckoutOptionEvent = (step, option, productData) =>
    createGtmEcommerceEvent('eec.checkout_option', {
        checkout_option: {
            actionField: {
                step: step,
                option: option,
            },
            products: productData,
        },
    });
