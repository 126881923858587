import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

export const initSentry = (dsn, environment, release, tracesSampleRate) => {
    Sentry.init({
        dsn,
        environment,
        release,
        tracesSampleRate,
        integrations: [new Integrations.BrowserTracing()],
    });
};
