// oživení "youtube" elementů (dle data-embed atributu)

window.onYouTubeIframeAPIReady = function () {
    document.body.querySelectorAll('.yt-video').forEach((el) => {
        const videoId = el.querySelector('.yt-video__trigger').dataset.embed;
        if (videoId) {
            // eslint-disable-next-line no-unused-vars
            const player = new YT.Player(el.querySelector('.yt-video__iframe'), {
                videoId: videoId,
                height: el.clientHeight,
                events: {
                    onReady: function (eventMain) {
                        const trigger = el.querySelector('.yt-video__trigger');
                        trigger.addEventListener('click', (eventClick) => {
                            eventClick.preventDefault();
                            trigger.classList.add('d-none');
                            el.querySelector('.yt-video__iframe').classList.remove('d-none');
                            eventMain.target.playVideo();
                        });
                    },
                },
            });
        }
    });
};

export const hydrateYoutubeElements = () => {
    if (document.body.querySelectorAll('.yt-video').length) {
        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
    }
};
