import { getPropertyBySymbolKey } from '../object/object';

const getPathFromUrl = (url) => {
    if (!url) {
        return;
    }
    if (url.indexOf('://') === -1) {
        return url;
    }
    const urlObject = new URL(url);
    return urlObject.pathname + urlObject.search + urlObject.hash;
};

const NAJA_ATTRIBUTE_SELECTOR = 'data-naja-spinner-selector';

export class LoaderExtension {
    constructor(naja, spinnerSelector, uiBlocker, displayErrorHandler) {
        this.loaders = [];
        this.unblockAll = 'off';
        this.naja = naja;
        this.uiBlocker = uiBlocker;
        this.displayErrorHandler = displayErrorHandler;

        naja.addEventListener('init', () => {
            this.loader = document.querySelector(spinnerSelector);
        });

        naja.addEventListener('interaction', this.saveLoaders.bind(this));
        naja.addEventListener('before', this.saveLoadersFromOptions.bind(this));
        naja.addEventListener('start', this.showLoader.bind(this));
        naja.addEventListener('complete', this.hideLoader.bind(this));
        naja.addEventListener('abort', this.hideLoader.bind(this));
        naja.addEventListener('error', this.showError.bind(this));
    }

    addLoader(element, url, initialized) {
        this.loaders.push({ element, url, initialized });
    }

    saveLoaders(event) {
        const element = event.element;
        const selector = element.form
            ? element.form.getAttribute(NAJA_ATTRIBUTE_SELECTOR)
            : element.getAttribute(NAJA_ATTRIBUTE_SELECTOR);

        this.addLoader(
            selector || (element.form ? element.form : element),
            element.form
                ? getPathFromUrl(event.url || element.form.action)
                : getPathFromUrl(event.url || element.href || element.action || element.baseURI),
            false
        );

        // Stav on už se nemění až do odblokování
        if (this.unblockAll !== 'on') {
            this.unblockAll = element.getAttribute('data-naja-unblock-all');
        }
    }

    saveLoadersFromOptions(event) {
        if (event !== undefined && event.options !== undefined && event.options.spinnerSelector !== undefined) {
            this.addLoader(event.options.spinnerSelector, getPathFromUrl(event.url), false);
        }
    }

    showLoader(event) {
        if (!this.loaders.length) {
            return;
        }

        const unInitializedLoaders = this.loaders.filter((filter) => !filter.initialized);

        unInitializedLoaders.forEach((loader, index, unInitializedLoaders) => {
            this.uiBlocker.block(loader.element);
            loader.initialized = true;

            if (!event.xhr.onabort) {
                event.xhr.onabort = () => {
                    this.uiBlocker.unblock(loader.element);
                    unInitializedLoaders.splice(index, 1);
                };
            }
        });
    }

    hideLoader(event) {
        if (!this.loaders.length) {
            return;
        }

        const url = getPathFromUrl(event.xhr.responseURL);

        const originalEvent = getPropertyBySymbolKey(event, 'original_event');
        // Pokud přijde z API response bez erroru, ale máme již zobrazený error alert, tak ho skryjeme
        if (this.displayErrorHandler && !originalEvent.error) {
            this.displayErrorHandler.hideError();
        }

        this.loaders.forEach((loader, index, loadersWithResponseUrl) => {
            // Pokud máme odblokovat vše, učiníme tak (hodí se tam, kde se URL mění)
            if (this.unblockAll === 'on') {
                this.uiBlocker.unblockAll();
                this.unblockAll = 'off';
            } else if (loader.url === url) {
                try {
                    this.uiBlocker.unblock(loader.element);
                } catch (e) {
                    // Element uz neexistuje, odstranime vsechny spinnery, aby tam nezustaly viset
                    console.warn(e);
                    console.warn('Unblocking all elements');
                    this.uiBlocker.unblockAll();
                }
                loadersWithResponseUrl.splice(index, 1);
            }
        });
    }

    showError(event) {
        this.uiBlocker.unblockAll();

        if (event.options.silentError === true) {
            return;
        }

        if (this.displayErrorHandler) {
            const originalEvent = getPropertyBySymbolKey(event, 'original_event');
            if (originalEvent) {
                this.displayErrorHandler.displayError(originalEvent.error);
            }
        }

        console.log('error', event, event.options);
    }
}
