import Slider from 'bootstrap-slider';
import { submitAfterChange } from '../../shared/form/submit-on-change';

export const initSliders = (sliderSelector) => {
    const sliders = document.querySelectorAll(sliderSelector);

    for (let i = 0; i < sliders.length; i++) {
        const sliderElement = sliders[i];

        const fromInput = document.querySelector(sliderElement.getAttribute('data-linked-input-from-selector'));
        const toInput = document.querySelector(sliderElement.getAttribute('data-linked-input-to-selector'));

        const config = {};

        if (fromInput !== null && toInput !== null) {
            config['value'] = [parseInt(fromInput.value), parseInt(toInput.value)];
        }

        // eslint-disable-next-line no-unused-vars
        const value = [
            fromInput !== null ? parseInt(fromInput.value) : null,
            toInput !== null ? parseInt(toInput.value) : null,
        ];

        const slider = new Slider(sliderElement, config);

        slider.on('slide', (values) => {
            const range = values;

            if (fromInput !== null) {
                fromInput.value = range[0];
            }

            if (toInput !== null) {
                toInput.value = range[1];
            }
        });

        if (sliderElement.classList.contains('submit-on-change__trigger')) {
            // eslint-disable-next-line no-unused-vars
            slider.on('slideStop', (oldValue, newValue) => {
                // console.log('ss');
                submitAfterChange(sliderElement);
            });
        }
    }
};
