export function initSmoothScroll(scope) {
    const prefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(prefix + '.smooth-scroll').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            const target = document.querySelector(event.currentTarget.getAttribute('href'));

            if (!target) {
                return;
            }

            window.scrollTo(null, target.getBoundingClientRect().top + document.documentElement.scrollTop);
        });
    });
}
