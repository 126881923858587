const cookieConsent = initCookieConsent();

export function initRtsoftCookieConsent(currentLang, languages) {
    const ccConfig = {
        autorun: true,
        current_lang: currentLang,
        autoclear_cookies: true,
        page_scripts: true,

        gui_options: {
            consent_modal: {
                layout: 'cloud',
                position: 'bottom center',
            },
        },
        languages,
    };

    cookieConsent.run(ccConfig);
}

export default cookieConsent;
