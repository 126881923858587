export const initNumericInputs = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.input-numeric').forEach((element) => {
        element.addEventListener('keypress', (event) => {
            const allowedChars = '0123456789';

            if (event.key.length === 1 && allowedChars.indexOf(event.key) === -1) {
                event.preventDefault();
            }
        });
    });
};
