import { loadFromStorage, saveToStorage } from '../../../library/localStorage/local-storage';

const INFO_BAR_LOCAL_STORAGE_KEY = 'info_bar';
const INFO_BAR_OPEN_CLASS = 'info-bar--open';

const hideInfoBarElement = (infoBarElement) => {
    const isOpened = infoBarElement.classList.contains(INFO_BAR_OPEN_CLASS);

    if (!isOpened) {
        return;
    }

    infoBarElement.classList.remove(INFO_BAR_OPEN_CLASS);
    saveToStorage(INFO_BAR_LOCAL_STORAGE_KEY, true);
};

const showInfoBarElement = (infoBarElement) => {
    infoBarElement.classList.add(INFO_BAR_OPEN_CLASS);
};

export const initInfoBar = () => {
    const infoBarElement = document.getElementById('info-bar');
    const infoBarCancelElement = document.getElementById('info-bar-close');

    if (!infoBarCancelElement || !infoBarElement) {
        return;
    }

    const isInfoBarClosed = loadFromStorage(INFO_BAR_LOCAL_STORAGE_KEY);

    if (isInfoBarClosed) {
        hideInfoBarElement(infoBarElement);
        return;
    }

    showInfoBarElement(infoBarElement);

    infoBarCancelElement.addEventListener('click', () => {
        hideInfoBarElement(infoBarElement);
    });
};
