const displayConnectionErrorAlert = () => {
    document.getElementById('connection-error-alert-container').style.display = 'block';
};

const hideConnectionErrorAlert = () => {
    document.getElementById('connection-error-alert-container').style.display = 'none';
};

const displayGeneralErrorAlert = () => {
    document.getElementById('general-error-alert-container').style.display = 'block';
};

const hideGeneralErrorAlert = () => {
    document.getElementById('general-error-alert-container').style.display = 'none';
};

export const displayConnectionErrorHandler = {
    displayError: (error) => {
        if (error.message === 'Connection aborted') {
            displayConnectionErrorAlert();
        } else {
            displayGeneralErrorAlert();
        }
    },
    hideError: () => {
        hideConnectionErrorAlert();
        hideGeneralErrorAlert();
    },
};
