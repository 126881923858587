import * as bootstrapModal from 'bootstrap/js/dist/modal';

export const handleLoginModalFormSuccess = () => {
    const status = document.getElementById('loginSuccess');
    if (status && status.dataset.status && status.dataset.redirectUrl) {
        window.location.href = status.dataset.redirectUrl;
    }
};

export const handleCartModalOpen = () => {
    const modal = bootstrapModal.getOrCreateInstance(document.getElementById('addToCartModal'));
    modal.show();
};
